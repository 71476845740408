const Custom404 = () => {
    return ( 
        <div className="flex items-center justify-center h-[100vh] text-center">
            <div className="leading-10">
                <h1 className="inline-block text-2xl font-medium align-top pr-6 mr-6 border-r-[1px] border-primaryLight dark:border-primaryDark">404</h1>
                <div className="inline-block">
                    <h2 className="text-sm font-normal leading-7 dark:text-lightBackground">The page could not be found</h2>
                </div>
            </div>
        </div>
     );
}
 
export default Custom404;